const getFbImageRealRef = (ref: string) => {
  let realRef: any;

  ['jpg', 'jpeg', 'png'].forEach(ext => {
    if (ref?.search(`.${ext}`) > 0) {
      realRef = ref.replace(`.${ext}`, `_600x600.${ext}`);
    }
  });

  return realRef;
};

export default getFbImageRealRef;
