const providesTagsFromOneResult =
  <T>(type: T) =>
  (result: any) =>
    result
      ? [
          {
            type,
            id: result._id,
          },
          {type: type, id: 'LIST'},
        ]
      : [{type: type, id: 'LIST'}];

export default providesTagsFromOneResult;
