import {AppBar as MuiAppBar} from '@mui/material';

const AppBar = (props: Omit<typeof MuiAppBar, 'position' | 'color' | 'sx'>) => (
  <MuiAppBar
    position="fixed"
    color="primary"
    sx={{
      top: 'auto',
      bottom: 0,
      backgroundColor: theme => theme.palette.common.white,
      color: theme => theme.palette.common.black,
    }}
    {...props}
  />
);

export default AppBar;
