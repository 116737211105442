import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {
  PlaceAutocompleteResponseData,
  PlaceDetailsResponseData,
} from '@googlemaps/google-maps-services-js';

import {authBaseQuery} from './apiBaseQuery';

export const autocompleteApi = createApi({
  reducerPath: 'autocompleteApi',
  baseQuery: authBaseQuery,
  endpoints(build) {
    return {
      autoCompletePlaces: build.query<PlaceAutocompleteResponseData, string>({
        query: search => ({
          url: '/getPlaces',
          method: 'get',
          params: {
            input: search,
          },
        }),
      }),
    };
  },
});

export const geocodingApi = createApi({
  reducerPath: 'geocodingApi',
  baseQuery: authBaseQuery,
  endpoints(build) {
    return {
      getPlaceById: build.query<PlaceDetailsResponseData, string>({
        query: placeId => ({
          url: '/getPlace',
          method: 'get',
          params: {
            placeId,
          },
        }),
      }),
    };
  },
});

export const {useAutoCompletePlacesQuery, useLazyAutoCompletePlacesQuery} =
  autocompleteApi;

export const {useGetPlaceByIdQuery, useLazyGetPlaceByIdQuery} = geocodingApi;
