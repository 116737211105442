import {useState} from 'react';
import {LanguageCode, supportedLanguagesMap} from '../index';

const APP_LOCALE = '@app/locale';

const isLocaleSupported = (locale?: string | null) =>
  locale && Object.keys(supportedLanguagesMap).includes(locale);

const useLocalStorageLocale = (): [
  LanguageCode | undefined,
  (newLocale: LanguageCode) => void,
] => {
  const [locale, setLocale] = useState<LanguageCode | undefined>(() => {
    const localLocale = localStorage.getItem(APP_LOCALE);
    return isLocaleSupported(localLocale)
      ? (localLocale as LanguageCode)
      : undefined;
  });

  const changeLocale = (newLocale: LanguageCode) => {
    setLocale(newLocale);
    localStorage.setItem(APP_LOCALE, newLocale);
  };

  return [locale, changeLocale];
};

export default useLocalStorageLocale;
