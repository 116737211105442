const providesTagsArray =
  <T extends string | string[]>(type: T) =>
  (result: {_id: string}[] | undefined) =>
    result
      ? [
          ...result.map(({_id}) => ({
            type,
            id: _id,
          })),
          {type, id: 'LIST'},
        ]
      : [{type, id: 'LIST'}];

export default providesTagsArray;
