import React from 'react';

import {IconButton, IconButtonProps, useTheme} from '@mui/material';

const RoundIconButton: React.FC<
  | {
      children: React.ReactNode;
      color?: 'primary' | 'secondary';
      sx?: IconButtonProps['sx'];
    }
  | IconButtonProps
> = ({color = 'primary', sx, ...props}) => {
  const theme = useTheme();
  const backgroundColor =
    color === 'primary'
      ? theme.palette.primary.main
      : theme.palette.common.white;
  return (
    <IconButton
      sx={{
        ...sx,
        backgroundColor,
        color: theme.palette.getContrastText(backgroundColor),
        borderRadius: '50%',
        flex: 0,
      }}
      {...props}
    />
  );
};

export default RoundIconButton;
