import {useContext} from 'react';
import {AppLocaleContext} from '../components/AppLocaleProvider';

const useAppLanguage = () => {
  const {locale} = useContext(AppLocaleContext);

  return locale;
};

export default useAppLanguage;
