import config from '../config.json';

import {BaseQueryFn} from '@reduxjs/toolkit/query/react';

import firebase from '../lib/app/firebase';

import axios, {AxiosError, AxiosRequestConfig} from 'axios';

const AxiosBaseQuery =
  (
    {baseUrl, includeAuth}: {baseUrl: string; includeAuth?: boolean} = {
      baseUrl: '',
      includeAuth: false,
    },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      responseType?: AxiosRequestConfig['responseType'];
    },
    unknown,
    unknown
  > =>
  async ({url, method, data, params, responseType = undefined}) => {
    try {
      const headers: AxiosRequestConfig['headers'] = {};
      // console.log(
      //   `${method}:${baseUrl}${url}`,
      //   method === 'get'
      //     ? params && JSON.stringify(params)
      //     : data && JSON.stringify(data),
      // );
      if (includeAuth) {
        const idToken = await firebase.auth().currentUser?.getIdToken();
        headers.authorization = `Bearer ${idToken}`;
      }

      if (data) {
        for (let key in data) {
          if (typeof data[key] === 'string') {
            data[key] = data[key].trim();
          }
        }
      }

      if (params) {
        for (let key in params) {
          if (typeof params[key] === 'string') {
            params[key] = params[key].trim();
          }
        }
      }

      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
        responseType,
      });

      return {data: result.data};
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiBaseQuery = AxiosBaseQuery({
  baseUrl: `${config.baseURL}/api` || '',
});

export const baseBaseQuery = AxiosBaseQuery({
  baseUrl: config.baseURL || '',
  includeAuth: false,
});

export const authBaseQuery = AxiosBaseQuery({
  baseUrl: config.baseURL || '',
  includeAuth: true,
});
