import React, {createContext} from 'react';
import {IntlProvider} from 'react-intl';
import intlMessages from '../../../app/intl';
import useLocalStorageLocale from '../hooks/useLocalStorageLocale';
import {
  defaultRichTextElements,
  DEFAULT_BROWSER_LANGUAGE,
  LanguageCode,
} from '../index';

type AppLocaleContextType = {
  locale: LanguageCode;
  setLocale: (lang: LanguageCode) => void;
};

export const AppLocaleContext = createContext<AppLocaleContextType>({
  locale: 'EN',
  setLocale: (lang: LanguageCode) => {},
});

const AppLocaleProvider = ({children}: {children: React.ReactNode}) => {
  const [storageLocale, setStorageLocale] = useLocalStorageLocale();
  const [locale, setLocale] = React.useState<LanguageCode>(
    storageLocale ?? DEFAULT_BROWSER_LANGUAGE,
  );

  const handleSetLocale = (lang: LanguageCode) => {
    setLocale(lang);
    setStorageLocale(lang);
  };

  return (
    <AppLocaleContext.Provider value={{locale, setLocale: handleSetLocale}}>
      <IntlProvider
        locale={locale}
        messages={intlMessages[locale]}
        defaultRichTextElements={defaultRichTextElements}>
        {children}
      </IntlProvider>
    </AppLocaleContext.Provider>
  );
};

export default AppLocaleProvider;
