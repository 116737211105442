import React from 'react';

import Checkbox, {CheckboxProps} from '@mui/material/Checkbox';

import {Field, FieldProps} from 'formik';

declare type Props = {name: string} & Omit<CheckboxProps, 'value'>;

const FormikSingleChoice: React.FC<Props> = ({name, ...props}) => {
  return (
    <Field name={name}>
      {({field, form}: FieldProps) => (
        <Checkbox
          checked={field.value === true}
          onChange={() => {
            form.setFieldValue(name, !field.value);
          }}
          {...props}
        />
      )}
    </Field>
  );
};

export default FormikSingleChoice;
