const providesTagsPagination =
  <T extends string | string[]>(type: T) =>
  (
    result:
      | {
          data: {_id: string}[];
        }
      | undefined,
  ) =>
    result
      ? [
          ...result.data.map(({_id}) => ({
            type,
            id: _id,
          })),
          {type, id: 'LIST'},
        ]
      : [{type, id: 'LIST'}];

export default providesTagsPagination;
